<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Subscription
        </h1>
        <!-- <SlideoutTabs default_width="half" :model="{id:item.id,type:'Subscription',route:`/subscription/${item.id}/`}" :contents="tab_content"  orientation="top-0 bottom-0 right-0" position="fixed"> -->
        <!-- </SlideoutTabs> -->

        <div class="flex w-full">
            <div class="w-1/2">
                <FormTemplate class="p-2" @response="formResponse" button="Create" method="post" action="/subscription/create" :formdata="formData">
                    <TextInput type="text" label="Amount" v-model="item.amount" :required="true" ></TextInput>
                    <SelectInput label="Status" v-model="item.status" :options="[{label:'Active',value:'Active'},{label:'Inactive',value:'Inactive'},{label:'Terminated',value:'Terminated'},{label: 'Canceled',value:'Canceled'},{label: 'Cancelled',value:'Cancelled'}]" :required="true" ></SelectInput>
                    <TextInput type="text" label="Unit" v-model="item.unit" :required="true" ></TextInput>
                    <TextInput type="text" label="Unit Length" v-model="item.unit_length" :required="false" ></TextInput>

                    <TextInput type="text" label="Duration" v-model="item.duration" :required="false" ></TextInput>
                    
                    <div class="input-wrapper p-2 flex flex-col relative">
                        <label class="text-normal text-open mb-2" for="StartDate" >Start Date</label>
                        <input id="StartDate" class="text-normal text-open px-4 py-1 border" type="date" v-model="item.startDate" :min="getDate" :required="true"></date>
                    </div>

                    <div class="m-4" v-if="item.agency">
                        <Checkbox label="Create New Payment Profile" v-model="item.new_payment" :required="false" ></Checkbox>
                        <FormTemplate v-if="item.new_payment" class="p-2" @response="formResponse" button="Create" method="post" action="/payment-profiles/create" :formdata="formData">
                            <TextInput type="text" label="Card Number" v-model="item.cardNum" :required="false" ></TextInput>
                            <TextInput type="text" label="Expiration Date (yyyy-mm)" v-model="item.cardExp" :required="false" ></TextInput>
                            <TextInput type="text" label="First Name" v-model="item.fn" :required="false" ></TextInput>
                            <TextInput type="text" label="Last Name" v-model="item.ln" :required="false" ></TextInput>
                            
                            <TextInput type="text" label="Billing Address" v-model="item.billingAddress" :required="false" ></TextInput>
                            <TextInput type="text" label="Billing City" v-model="item.billingCity" :required="false" ></TextInput>
                            <TextInput type="text" label="Billing State" v-model="item.billingState" :required="false" ></TextInput>
                            <TextInput type="text" label="Billing Zip" v-model="item.billingZip" :required="false" ></TextInput>
                            <TextInput type="text" label="Billing Country" v-model="item.billingCountry" :required="false" ></TextInput>
                            <TextInput type="text" label="Billing Phone" v-model="item.billingPhone" :required="false" ></TextInput>
                        
                        </FormTemplate>
                        <SelectInput v-else @input="UpdatePaymentMethod" label="Payment Method" :required="false" :options="card_numbers"></SelectInput>
                    </div>

                    <SearchSelectInput @item:clicked="AgencyClick" @item:removed="accountRemoved" @item:selected="accountSelected" :params="accountSearchParams" :multiple="false" label="Agency" v-model="item.agency" :optiondisplay="accountDisplay" :hideSelected="false" :required="true"></SearchSelectInput>
                    <router-link :to="{path:'/orders/'+item.order_id}" >
                        Order {{item.order_id}}
                    </router-link>
                    <p><strong>Date Created:</strong> {{getDate(item.created_at)}}</p>
                    <p><strong>Date Updated:</strong> {{getDate(item.updated_at)}}</p>
                </FormTemplate>
            </div>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'
import Notes from '../../../components/dashboard/Notes'
import AuthDotNetSub from '../../../components/dashboard/subscription/authdotnet/List'
import Licenses from '../../../components/dashboard/licenses/List'
import Products from '../../../components/dashboard/products/List'
import PaymentMethod from '../../../components/dashboard/paymentmethods/View'

export default {
    name: 'Subscription',
    mixins: [View],
    data: () => ({
        parameter: 'subscription_id',
        action: '/subscription/',
        // tab_content: {
        //     'notes': {
        //         label: 'Notes',
        //         icon: '/assets/notes.png',
        //         component: Notes,
        //         top: '200px',
        //     },
        //     'licenses': {
        //         label: 'Licenses',
        //         icon: '/assets/licenses.png',
        //         component: Licenses,
        //         top: '300px',
        //     },
        //     'products': {
        //         label: 'Products',
        //         icon: '/assets/products.png',
        //         component: Products,
        //         top: '400px',
        //     },
        //     'payment_method': {
        //         label: 'Payment Method',
        //         icon: '/assets/payment_methods.png',
        //         component: PaymentMethod,
        //         top: '500px',
        //     },
        // },
        authdotnet: false,
        accountSearchParams: {
            'model': 'Account',
            'fields': ['id','name','email','phone'],
            'action': '/search/',
        },
        item: {
            status: 'Active',
            unit: 'months',
            unit_length: 12,
            duration: 9999999,
            startDate: null,
            agency: null,
            cardNum: null,
            cardExp: null,
            fn: null,
            ln:null,
            new_payment: false,

            billingAddress: null,
            billingCity: null,
            billingState: null,
            billingCountry: null,
            billingZip: null,
            billingPhone: null,

            profile: null,
            method: null,
        },
        payment_methods: [],
        timeouts: {
            methods: false
        },

    }),
    computed: {
        formData() {
            return {
                'subscription':this.item,
            }
        },
        card_numbers() {
            var cardNums = [];
            var methods = this.payment_methods;
            this.payment_methods.forEach( function(method, index) {
                cardNums[index] = { label: method.card_number, value: method.uid}
            })
            return cardNums;
        }
    },
    props: [],
    methods: {
        GetPaymentMethods() {
            console.log("GetPaymentMethods");
            this.$requests.get(`/agency/${this.item.agency.id}/payment_methods`,
            {
                success: (re) => {
                    console.log(re.data)
                    this.payment_methods = re.data.items;
                    return true
                },
            });
        },
        UpdatePaymentMethod(evt) {
            console.log("UpdatePaymentMethod");
            console.log(evt);
            this.item.method = evt;
            this.item.profile = this.getProfileID(evt);
        },
        accountSelected(item) {
            console.log("accountSelected");
            this.$set(this.item,'account_id',item.item.id)
            this.$set(this.item,'agency',item.item)

            if(this.timeouts.methods) clearTimeout(this.timeouts.methods)
            this.timeouts.methods = setTimeout(this.GetPaymentMethods,1000)
        },
        accountRemoved() {
            this.$delete(this.item,'account_id')
            this.$delete(this.item,'agency')
        },
        AgencyClick() {
            this.$router.push({path:'/agencies/'+this.item.account_id})
        },
        accountDisplay(item) {
            return 'ID: '+item.id+' - Name: '+item.name
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
        itemFetched() {
            if(this.item && this.item.uid && this.item.uid.trim() != ''){
                this.$requests.get(`/subscription/${this.item.id}/authdotnet/subscription`,
                {
                    success: (re) => {
                        this.authdotnet = re.data.item
                        return true
                    },
                })
            }
        },
        getProfileID(method_id)
        {
            return this.payment_methods.find( ({uid}) => uid == method_id ).payment_profile_id;
        },
    },
    watch: {
        card_numbers(nums) {
            console.log(nums);
            if (nums[0])
                this.UpdatePaymentMethod(nums[0].value);
            return nums;
        }
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
        AuthDotNetSub,
    },
}
</script>
<style scoped>
</style>
