<template>
    <div class="flex flex-col" >
        <div @click="$router.push({path:`/products/${product.id}`})" class="flex-1 border-b-2 cursor-pointer py-2 px-4 text-purple text-md" v-for="(product,i) in products" :key="i">
            {{product.name}}
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'ProductsList',
    data: () => ({
        products: [],
    }),
    computed: {
    },
    props: ['model'],
    methods: {
        getProducts() {
            if(this.model) {
                this.$requests.get(`${this.model.route}products`,
                {
                    success: (re) => {
                        this.products = re.data.items
                        return true
                    },
                })
            }
        },
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
        this.getProducts()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
