<template>
<div class="my-2">
    <div v-if="subscription" class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
            <h3 class="text-md leading-6 font-medium text-gray-900">
            Authorize.Net Details
            </h3>
        </div>
        <div class="border-t border-gray-200">
            <dl>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                    Status
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{subscription.status}}
                    </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                    Amount
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{subscription.amount}}
                    </dd>
                </div>
                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                    Description
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{subscription.description}}
                    </dd>
                </div>
                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                    Name
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {{subscription.name}}
                    </dd>
                </div>
                <div v-if="subscription.order" class="bg-gray px-4 py-5">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h3 class="text-md leading-6 font-medium text-gray-900">
                            Order
                            </h3>
                        </div>
                        <div class="border-t border-gray-200">
                            <dl>
                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Description
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.order.description}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Invoice
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.order.invoiceNumber}}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div v-if="subscription.payment_method" class="bg-gray px-4 py-5">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h3 class="text-md leading-6 font-medium text-gray-900">
                            Payment Method
                            </h3>
                        </div>
                        <div class="border-t border-gray-200">
                            <dl>
                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Bill To
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_method.billTo.firstName}} {{subscription.payment_method.billTo.lastName}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Customer type
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_method.customerType}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Card
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_method.payment.creditCard.cardNumber}}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div v-if="subscription.payment_profile" class="bg-gray px-4 py-5">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h3 class="text-md leading-6 font-medium text-gray-900">
                            Payment Profile
                            </h3>
                        </div>
                        <div class="border-t border-gray-200">
                            <dl>
                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Profile UID
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_profile.customerProfileId}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Description
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_profile.description}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Email
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_profile.email}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Merchant Customer UID
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_profile.merchantCustomerId}}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div v-if="subscription.payment_schedule" class="bg-gray px-4 py-5">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h3 class="text-md leading-6 font-medium text-gray-900">
                            Payment Schedule
                            </h3>
                        </div>
                        <div class="border-t border-gray-200">
                            <dl>
                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Unit Length
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.interval.length}}
                                    </dd>
                                </div>
                                <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Unit
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.interval.unit}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Start Date
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.startDate}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Total Occurences 
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.totalOccurrences}}
                                    </dd>
                                </div>
                                <div class="bg-gray px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Trial Occurences 
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    {{subscription.payment_schedule.trialOccurrences}}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div v-if="subscription.transactions" class="bg-gray px-4 py-5">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                        <div class="px-4 py-5 sm:px-6">
                            <h3 class="text-md leading-6 font-medium text-gray-900">
                            Transactions
                            </h3>
                        </div>
                        <div class="border-t border-gray-200">
                            <dl>
                                <div v-for="(transaction,i) in subscription.transactions" :key="i" class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt class="text-sm font-medium text-gray-500">
                                    Transaction
                                    </dt>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    ID: {{transaction.transId}} - Attempt Number: {{transaction.attemptNum}} - Payment Number: {{transaction.payNum}} - Response: {{transaction.response}} - Date: {{transaction.submitTimeUTC}}
                                    </dd>
                                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <a class="bg-red p-2 text-white" :href="`${$store.state.data.baseUrl}/billing/invoice/transaction/${transaction.transId}`" target="_blank" >Invoice</a>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </dl>
        </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
export default {
    name: 'AuthDotNetSubscriptionList',
    data: () => ({
    }),
    computed: {
    },
    props: ['subscription'],
    mixins: [],
    methods: {
        getDate(datetime) {
            return moment(datetime).format('MMMM Do YYYY');
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
